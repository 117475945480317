<template>
	<div class="gg-container">
		<el-button type="danger" @click="closeDrawer"
							 style="position: absolute; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px 0 0 4px; top: 140px; left: -40px;width: 40px; height: 40px;">
			<i class="iconfont icon-cha" style="font-size: 24px"></i>
		</el-button>
		<div class="caption-container">
			<p class="caption-title">患者信息</p>
			<div class="caption-wrapper">
				<div class="caption-avatar"><el-avatar :size="60" :src="circleUrl"></el-avatar></div>
				<div class="caption-info">
					<div class="caption-info-up">
						<span class="name">{{patientDetail.realname}}</span>
						<span class="mobile">{{patientDetail.mobile}}</span>
					</div>
					<div class="caption-info-down">
						<div class="gender">
							<div class="nv" v-if="'2' == patientDetail.gender">
								<i class="iconfont icon-nv"></i>
							</div>
							<div class="nan" v-else>
								<i class="iconfont icon-nan"></i>
							</div>
						</div>
						<div class="intro">
							<i class="iconfont icon-yishichangjing intro-icon"></i>
							<span class="intro-title">{{patientDetail.terminal_name}}</span>
						</div>
						
					</div>
				</div>
			</div>
		</div>
		
		<!--tabs-->
		<div class="gg-tabs">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="基本信息" name="first" v-loading="listLoading">
					<el-row>
						<el-col :span="6">
							<div class="grid-content bg-purple relation-container">
								<div style="display: flex;  align-items: center;">
									<div class="required"></div><span>处方信息</span>
								</div>
							</div>
						</el-col>
						<el-col :span="24">
						</el-col>
					</el-row>
					<el-row class="patient-detail">
						<el-col :span="2"><div class="grid-content bg-purple">
							姓名
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.realname}}
						</div></el-col>
						<el-col :span="2"><div class="grid-content bg-purple">
							昵称
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.nickname}}
						</div></el-col>
					</el-row>
					<el-row class="patient-detail">
						<el-col :span="2"><div class="grid-content bg-purple">
							手机号码
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.mobile}}
						</div></el-col>
						<el-col :span="2"><div class="grid-content bg-purple">
							性别
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.gender | genderFormat}}
						</div></el-col>
					</el-row>
					<el-row class="patient-detail">
						<el-col :span="2"><div class="grid-content bg-purple">
							来源
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.source_name}}
						</div></el-col>
						<el-col :span="2"><div class="grid-content bg-purple">
							终端
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.terminal_name}}
						</div></el-col>
					</el-row>
					<el-row class="patient-detail">
						<el-col :span="2"><div class="grid-content bg-purple">
							激活状态
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.active_status === 0 ? '待激活' : '激活'}}
						</div></el-col>
						<el-col :span="2"><div class="grid-content bg-purple">
							活跃时间
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{info.last_time}}
						</div></el-col>
					</el-row>
					<el-row class="patient-detail">
						<el-col :span="2"><div class="grid-content bg-purple">
							省市区
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.addr}}
						</div></el-col>
						<el-col :span="2"><div class="grid-content bg-purple">
							详细地址
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.address}}
						</div></el-col>
					</el-row>
					<el-row class="patient-detail">
						<el-col :span="2"><div class="grid-content bg-purple">
							生日
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.birthday}}
						</div></el-col>
						<el-col :span="2"><div class="grid-content bg-purple">
							原始微信
						</div></el-col>
						<el-col :span="10"><div class="detail-content grid-content bg-purple-light">
							{{patientDetail.mobile}}
						</div></el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="就诊人列表" name="second">
					<el-table
						v-loading="listLoading"
						:header-cell-style="{'text-align':'center'}"
						:data="tableData"
						stripe
						border
						style="width: 100%">
						<el-table-column
							fixed="left"
							prop="pat_id"
							label="ID"
							align="center">
						</el-table-column>
						<el-table-column
							prop="patient_name"
							label="姓名"
							align="center">
						</el-table-column>
						<el-table-column
							prop="head_portrait"
							label="图像"
							align="center">
							<template slot-scope="scope">
								<el-avatar :size="40" :src="scope.row.head_portrait || circleUrl" ></el-avatar>
							</template>
						</el-table-column>
						<el-table-column
							prop="age"
							label="年龄"
							align="center">
						</el-table-column>
						<el-table-column
							prop="patient_gender"
							label="性别"
							align="center">
							<template slot-scope="scope">
								{{scope.row.patient_gender | genderFormat}}
							</template>
						</el-table-column>
						<el-table-column
							prop="patient_phone"
							label="手机号"
							align="center">
						</el-table-column>
						<el-table-column
							prop="patient_ID"
							label="身份证"
							align="center">
						</el-table-column>
						<el-table-column
							prop="from_name"
							label="来源"
							align="center">
						</el-table-column>
						<el-table-column
							prop="relation_anme"
							label="与本人关系"
							align="center">
						</el-table-column>
						<el-table-column
							prop="past_medical"
							label="既往史"
							align="center">
						</el-table-column>
						<el-table-column
							prop="allergy_medical"
							label="过敏史"
							align="center">
							<template slot-scope="scope">
								<el-tooltip placement="top">
									<div slot="content">{{scope.row.allergy_medical}}<br/>{{scope.row.past_medical}}</div>
									<el-button type="text">{{scope.row.allergy_medical}}</el-button>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column
							fixed="right"
							align="center"
							label="操作">
							<template slot-scope="scope">
								<el-button
									size="mini"
									style="padding: 7px 8px;margin-left: 5px;"
									@click="handleInterest( scope.row)">发放会员权益
								</el-button>
								<el-button
									size="mini"
									type="danger"
									icon="el-icon-delete"
									style="padding: 7px 8px;margin-left: 5px;"
									@click="handleDelete(scope.$index, scope.row)">删除
								</el-button>
								
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			
				<el-tab-pane class="follow-record-class" label="随访记录" name="Third" style="height:650px;overflow-y:auto;overflow-x:hidden;">
					<el-table
						border
						:data="followTableData"
						v-loading="listLoading"
						:header-cell-style="{'text-align':'center'}"
						style="width: 100%">
						<el-table-column
							prop="record_time"
							label="提交确认时间"
							width="180"
							align="center">
						</el-table-column>
						<el-table-column
							prop="patient_name"
							label="随访对象"
							align="center">
						</el-table-column>
						<el-table-column
							prop="record_name"
							label="随访记录人"
							align="center">
						</el-table-column>
						<el-table-column
							prop="doctor_name"
							label="随访确认人"
							align="center">
						</el-table-column>
						<el-table-column
							prop="content"
							label="随访内容"
							width="500"
							align="center">
							<template slot-scope="scope">
								<el-tooltip placement="top">
									<div style="width: 750px;" slot="content">{{scope.row.content}}</div>
									<div style="width: 500px; text-align: center; white-space:nowrap;text-overflow:ellipsis; overflow: hidden">{{scope.row.content}}</div>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
					<pagination  :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
											 @pagination="_getPatientFollow"/>
				</el-tab-pane>
			
			</el-tabs>
		</div>
	</div>
</template>

<script>
import {delPatient, getMemberInfo, getPatientList,sendVipInterest,getPatientFollow} from '@/api/patientManagement';
import Pagination from '@/components/Pagination'
export default {
  name: "patientManagementDetails",
	components: {
    Pagination,
	},
	props: {
    info: {
      type: Object,
			required: true
		}
	},
  async created () {
    await this._patientDetail()
		await this._getPatientList()
    this._getPatientFollow()
  },
	data() {
    return {
      activeName: 'first',
      listLoading: false,
			patientDetail: {},
      gender: 'nv',
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      tableData: [],
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      searchParams: {
        orderTime: []
      },
      total: 0,
			followTableData: [],
		}
	},
	filters:{
    genderFormat(v) {
      if ( v===1 ){
        return '男'
			} else if( v===2 ) {
        return '女'
			} else {
        return '未知'
			}
		}
	},
	methods: {
		handleInterest(row){
			this.$confirm('确认发放会员权益?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sendVipInterest(row.pat_id).then(res=>{
          if(res.code === 200){
            this.$message({
              type: 'success',
              message: '发放成功!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

		},
    _patientDetail(rest) {
      this.listLoading = true
      getMemberInfo(this.info.id).then(response => {
        let data = response
        if (data.code === 200) {
          this.patientDetail = data.data
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    _getPatientList(rest) {
      this.listLoading = true
      let params = {
        ...this.searchParams
      }
      params.page = this.listQuery.page
      params.page_size = this.listQuery.limit
			// this.info.id 替换到 1111
      getPatientList(this.info.id).then(response => {
        let data = response
        if (data.code === 200) {
          this.tableData = data.data
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    _getPatientFollow (){
		  var params = {
		    id : this.info.id,
				page: this.listQuery.page,
				page_size: this.listQuery.limit
			}
      getPatientFollow(params).then(response => {
        console.log(response)
				if (response.code == 200) {
          this.followTableData = response.data.list
          this.total = response.data.total
				} else {
          this.$message({
            type: 'error',
            message: response.message
          })
				}
			}).catch(e => {
			  console.log(e)
			})
		},
		
    handleClick(tab, event) {
      console.log(tab, event);
      console.log(this.activeName)
      this.listQuery.page = 1
      this.listQuery.limit = 10
      },
    closeDrawer() {
      console.log(123)
      this.$emit('closeDrawer', false)
    },
    handleEdit(index, row) {},
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('确认删除文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row.pat_id)
        delPatient(row.pat_id).then(res=>{
          if(res.code === 200){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
	}
}
</script>

<style scoped lang="scss">
	/*竖杠+后面文本的样式*/
	.required {
		margin-top: -3px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required+span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	/*caption-container样式*/
	.caption-container{
		.caption-title{
			font-size: 16px;
			color: #111;
			font-weight: bold;
			margin-top: 0;
		}
		.caption-wrapper{
			display: flex;
			justify-content: left;
			align-items: center;
			.caption-avatar{}
			.caption-info{
				display: flex;
				flex-direction: column;
				margin-left: 20px;
				.caption-info-up{
					height: 30px;
					font-size: 16px;
					font-weight: bold;
					color: #111;
					.name{
						margin-right: 15px;
					}
				}
				.caption-info-down{
					display: flex;
					justify-content: left;
					align-items: center;
					height: 25px;
					.gender{
						width: 39px;
						.nv{
							display: flex;
							justify-content: center;
							align-items: center;
							width: 14px;
							height: 14px;
							background-color: #E03030;
							color: #fff;
						}
						.nan{
							display: flex;
							justify-content: center;
							align-items: center;
							width: 14px;
							height: 14px;
							background-color: #2362fb;
							color: #fff;
						}
					}
					.intro{
						display: flex;
						justify-content: left;
						align-items: center;
						background-color: #f7f7f7;
						color: #777;
						font-size: 14px;
						.intro-icon {
							margin-left: 5px;
							margin-right: 5px;
							padding-top: 1px;
							font-size: 16px;
						}
						.intro-title{
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	
	.gg-tabs {
		margin-top: 10px;
	}
	/*tabs下划线颜色*/
	::v-deep .el-tabs__item{
		font-weight: bold;
	}
	
	::v-deep .is-active{
		color: #2362fb;
	}
	
	::v-deep .el-tabs__active-bar{
		background-color: #2362fb;
	}
	
	/*栅格布局*/
	.bg-purple {
		/*background: #d3dce6;*/
	}
	.bg-purple-light {
		/*background: #e5e9f2;*/
	}
	.grid-content {
		border-radius: 4px;
		min-height: 30px;
		line-height: 30px;
	}
	
	
	.patient-detail{
		font-size: 14px;
		color: #777;
		.detail-content{
			color: #111;
		}
	}
	
	::-webkit-scrollbar {
		width: 7px;
		height: 5px;
		border-radius:15px;
		-webkit-border-radius:  15px;
	}
	::-webkit-scrollbar-track-piece {
		background-color: #ffff;
		border-radius:15px;
		-webkit-border-radius:  15px;
	}
	::-webkit-scrollbar-thumb:vertical {
		height: 5px;
		background-color: rgba(144, 147, 153, 0.5);
		border-radius: 15px;
		-webkit-border-radius:  15px;
	}
	::-webkit-scrollbar-thumb:horizontal {
		width: 7px;
		background-color: rgba(144, 147, 153, 0.5);
		border-radius:  15px;
		-webkit-border-radius: 15px;
	}
	
	::v-deep .el-tooltip{
			width: 750px;
		
	}
</style>
